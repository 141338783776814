<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="pt-0 pb-0" cols="12">
              <v-text-field
                ref="name"
                v-model="editedItem.name"
                :rules="[() => !!editedItem.name]"
                dense
                label="Название:"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-select
                type="number"
                v-model="editedItem.service_type"
                ref="service_type"
                :items="typeList"
                :rules="[() => !!editedItem.service_type]"
                dense
                label="Тип:"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-text-field
                ref="price"
                v-model.number="editedItem.price"
                :rules="[() => !!editedItem.price]"
                dense
                label="Цена:"
                type="number"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="close"> Отменить </v-btn>
        <v-btn color="primary" text @click="save" :disabled="disabledBtn">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import user from "../../../store/auth";
import { ServiceApiUrls } from "@/services/accommodationRequests/service.api.js"
import toast from "../../../plugins/toast";

export default {
  name: "Service",
  data() {
    return {
      disabledBtn: false,
      editedItem: {
        id: null,
        name: null,
        service_type: null,
        price: null,
        company: null,
      },
      typeList: [
        { text: "Единоразово", value: "once" },
        { text: "Cутки", value: "day" },
        // { text: 'Cкидки', value: 'sale' },
      ],
    };
  },
  validations: {
    editedItem: {
      name: { required },
      service_type: { required },
      price: { required },
    },
  },
  computed: {
    formTitle() {
      return this.$route.params.id ? "Редактировать" : "Создать";
    },
  },
  async created() {
    if (this.$route.params.id) {
      this.editedItem = await ServiceApiUrls.getService(this.$route.params.id)
    }
  },
  methods: {
    close() {
      this.$router.push("/services");
    },
    async save() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        Object.keys(this.editedItem).forEach((f) => {
          if (this.$refs[f]) {
            this.$refs[f].validate(true);
          }
        });
        return;
      }
      this.editedItem.company = Number(user.getters.getCompany);
      if (this.$route.params.id) {
        this.disabledBtn = true;
        try{
          await ServiceApiUrls.setService(this.$route.params.id, this.editedItem)
          this.disabledBtn = false;
          toast.success("Успешно изменено!")
          this.$router.push("/services")
        }
        catch(e){
          this.disabledBtn = false
        }
      } else {
        this.disabledBtn = true;
        try{
          await ServiceApiUrls.createService(this.editedItem)
          this.disabledBtn = false;
          toast.success("Успешно создано!")
          this.$router.push("/services")
        }
        catch(e){
          this.disabledBtn = false
        }
      }
    },
  },
};
</script>

<style scoped></style>
